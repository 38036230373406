import * as React from 'react'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DeleteConfirmDialog } from '../../components/Dialog'
import { Breakdown as BillBreakdown } from '../../Billing/BreakdownDialog'
import { convertTimestampToTimeZone, formatNumber } from '../../util'
import { ErrorBoundary } from '../../components/ErrorBoundary'
import { FormDialog as BillForm, FormValues } from '../../Billing/FormDialog'
import { useBills } from '../../Billing/useBills'
import type { Bill, IInvoices, ILineItems } from '../../Billing/types'
import { Address, RateMethod } from '../../types'
import { StructureBilling } from '../types'
import {
  OUTAGE_STATUS_REFRESH_MILLIS,
  OUTAGE_STATUS_SWR_KEY,
  mapOutageStatusToIsElasticsearchOutage
} from '@/Outage/util'
import { defaultOutageStatusResponse, getOutageStatus } from '@/Outage/api'
import useSWR from 'swr'
import { OrderIcon } from '@/components/OrderIcon'
import { generateBill } from '@/Billing/api'
import { SWLoadingButton } from '@/components/LoadingButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import useMixPanel from '@/hooks/useMixPanel'
import { useModal } from '@/components/ModalLoading/context'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import { Unit } from '@/Units/types'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

function LinearProgressWithLabel(props: LinearProgressProps & { value: number; label: string }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography fontSize={10} sx={{ color: 'text.secondary' }}>
          {props.label}
        </Typography>
      </Box>
    </Box>
  )
}

type SortDirection = 'asc' | 'desc'

type SortOrder = -1 | 0 | 1

type SortableProperty = 'name' | 'start' | 'end' | 'volume' | 'cost'

type RowData = {
  id: string
  name: string
  start: number
  end: number
  volume: number
  cost: number
  rateMethod?: RateMethod
  effectiveRate?: number | null
  volumeUnits: string
  billsSentTimestamp: number | null | undefined
  invoices: IInvoices[]
  lineItems: ILineItems[]
}

type Comparator = (a: RowData, b: RowData, orderBy?: SortableProperty) => SortOrder

// Util ///////////////////////////////////////////////////////////////////////

/**
 * Sort in descending order
 *
 * @param {object} a - First object to compare
 * @param {object} b - Second object to compare
 * @param {string} orderBy - Property name to order by
 *
 * @returns {-1 | 0 | 1} - Sort order
 */
const descendingComparator = (a: RowData, b: RowData, orderBy: SortableProperty) => {
  // $FlowFixMe
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  // $FlowFixMe
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

/**
 * Get sorting function
 *
 * @param {'asc' | 'desc'} order - Direction to sort
 * @param {*} orderBy - Property name to order by
 *
 * @returns {Function} - Function to sort with
 */
const getComparator = (order: SortDirection, orderBy: SortableProperty): Comparator => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy) as SortOrder
    : (a, b) => -descendingComparator(a, b, orderBy) as SortOrder
}

/**
 * Sort row data
 *
 * @param {RowData[]} array - Array of RowData
 * @param {Function} comparator - Comparing/sorting function
 *
 * @returns {RowData[]} - Sorted RowData
 */

const hiddenArrow = () => <span />
const stableSort = (array: RowData[], comparator: Comparator): RowData[] => {
  const stabilizedThis = array.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    // $FlowFixMe
    const order = comparator(a[0] as RowData, b[0] as RowData)

    if (order !== 0) return order

    return (a as number[])[1] - (b as number[])[1]
  })

  return stabilizedThis.map((el) => el[0] as RowData)
}

/**
 * Convert Bills to table rows
 *
 * @param  {object[]} billArr - Array of Bills
 * @returns {object[]} - Array of rows
 */
const billsToRows = (billArr: Bill[]): RowData[] => {
  return billArr.map((b) => ({
    id: b.id,
    name: b.name || '',
    start: b.startDate,
    end: b.endDate,
    cost: b.cost,
    volume: b.volume,
    rateMethod: b.rateMethod,
    volumeUnits: b.volumeUnits,
    effectiveRate: b.effectiveRate,
    billsSentTimestamp: b.billsSentTimestamp,
    invoices: b.invoices,
    lineItems: b.lineItems
  }))
}

// Components /////////////////////////////////////////////////////////////////

// Head ///////////////////////////////

let headCells = [
  { id: 'cost', numeric: false, disablePadding: false, label: 'Amount' },
  // { id: 'start', numeric: false, disablePadding: false, label: 'Start Date' },
  { id: 'end', numeric: false, disablePadding: false, label: 'Due' }, //TODO add real due date
  { id: 'volume', numeric: false, disablePadding: false, label: 'Water Usage' },
  { id: 'end', numeric: false, disablePadding: false, label: 'Billing Period' },
  // { id: 'unit', numeric: false, disablePadding: false, label: 'Volume Unit' },
  // { id: 'rate', numeric: false, disablePadding: false, label: 'Effective Rate' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' }
]

type HeadProps = {
  classes: { [x: string]: string }
  order: SortDirection
  orderBy: SortableProperty
  onRequestSort: (event: React.MouseEvent, property: SortableProperty) => void
  stripeConnect: boolean
}

const EnhancedTableHead = ({ classes, order, orderBy, onRequestSort, stripeConnect }: HeadProps) => {
  const createSortHandler = (property: SortableProperty) => (event: React.MouseEvent) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.id === 'status' && !stripeConnect) {
            return <></>
          }
          return (
            <TableCell key={headCell.id} align="center" sortDirection={false}>
              <TableSortLabel IconComponent={hiddenArrow} onClick={createSortHandler(headCell.id as SortableProperty)}>
                {headCell.label}
                {orderBy === headCell.id && (
                  <span
                    style={{
                      position: 'absolute',
                      right: '-25px'
                    }}
                  >
                    <OrderIcon type={order} />
                  </span>
                )}
              </TableSortLabel>
            </TableCell>
          )
        })}
        <TableCell align={'justify'}></TableCell>
      </TableRow>
    </TableHead>
  )
}

const EnhancedTableToolbar = ({ onAdd }) => {
  return (
    <Box margin={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={0}>
        <Typography variant="h6" id="tableTitle" component="div">
          Water Utility Bill
        </Typography>
        <SWLoadingButton onClick={onAdd} sx={{ ml: 2 }}>
          Add Bill
        </SWLoadingButton>
      </Stack>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: `${theme.spacing(4)} 0 ${theme.spacing(3)}`
  },
  paper: {
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 650
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}))

type Props = {
  bills: Bill[]
  structureId: string
  structureName: string
  isLoading: boolean
  defaultRateMethod: RateMethod
  structureAddress: Address
  timeZone: string
  stripeConnect: boolean
  units: Unit[]
  volumeUnits: string
}

export const Billing = ({
  bills,
  structureId,
  structureName,
  isLoading,
  defaultRateMethod,
  structureAddress,
  timeZone,
  stripeConnect,
  units,
  volumeUnits
}: Props): JSX.Element => {
  const [order, setOrder] = React.useState<SortDirection>('desc')
  const [orderBy, setOrderBy] = React.useState<SortableProperty>('start')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [isAddBillOpen, setIsAddBillOpen] = React.useState(false)
  const [isEditingBill, setIsEditingBill] = React.useState<string | null>(null)
  const [selectedBillingDetail, setSelectedBillingDetail] = React.useState<StructureBilling | null>(null)
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false)
  const [selectedRowId, setSelectedRowId] = React.useState<string | null>(null)
  const [selectedRow, setSelectedRow] = React.useState<RowData>()
  const [loading, setLoading] = React.useState(false)
  const { create, edit, remove } = useBills(structureId)
  const [billingDetailAction, setBillingDetailAction] = React.useState<'csv' | 'pdf' | null>(null)
  const [invoicesData, setInvoicesData] = React.useState([])
  const [lineItems, setLineItems] = React.useState([])
  const [isReportTenant, setIsReportTenant] = React.useState<boolean>(false)
  const [isSendBill, setIsSendBill] = React.useState<boolean>(false)
  const [isPaymentReport, setIsPaymentReport] = React.useState<boolean>(false)
  const [billsSentTimestamp, setBillsSentTimestamp] = React.useState<number | null>(null)
  const { trackEvent } = useMixPanel()
  const { showLoading, hideLoading } = useModal()

  const { data: outageStatusResponse = defaultOutageStatusResponse } = useSWR(OUTAGE_STATUS_SWR_KEY, getOutageStatus, {
    refreshInterval: OUTAGE_STATUS_REFRESH_MILLIS
  })

  const generateBillForSelectedId = (selectedBillId, info, action?: 'csv' | 'pdf') => {
    if (selectedBillId == null) return
    showLoading(
      'Preparing Tenant Invoices',
      'Were creating your tenants invoices ready to send. This will only take a moment.'
    )

    setLoading(true)
    trackEvent('Bill Generated', info)
    generateBill(selectedBillId, mapOutageStatusToIsElasticsearchOutage(outageStatusResponse))
      .then((resp) => {
        setInvoicesData(info.invoices)
        setLineItems(info.lineItems)
        setBillsSentTimestamp(info.billsSentTimestamp)
        if (action) setBillingDetailAction(action)
        setSelectedBillingDetail(resp)
      })
      .finally(() => {
        setLoading(false)
        hideLoading()
      })
  }

  const classes = useStyles()

  const rows: RowData[] = billsToRows(bills)

  const handleRequestSort = (event: React.MouseEvent, property: SortableProperty) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.currentTarget.value, 10))
    setPage(0)
  }

  const handleClickDelete = (rowId, rowInfo) => {
    setSelectedRowId(rowId)
    setSelectedRow(rowInfo)
    setOpenConfirmModal(true)
  }

  const handleDeleteConfirm = async () => {
    if (selectedRowId && selectedRow) {
      setLoading(true)
      await remove(selectedRowId)
      await trackEvent('Bill Deleted', {
        StartDate: convertTimestampToTimeZone(selectedRow.start, timeZone),
        EndDate: convertTimestampToTimeZone(selectedRow.end, timeZone),
        WaterUsage: selectedRow.volume,
        VolumeUnit: selectedRow.volumeUnits || 'GALLONS_1K',
        Cost: selectedRow.cost,
        RateMethod: selectedRow.rateMethod,
        PropertyName: structureName,
        PropertyID: structureId,
        BillID: selectedRowId,
        EffectiveRate: selectedRow.effectiveRate
      })
      setLoading(false)
      setSelectedRowId(null)
    }

    setOpenConfirmModal(false)
    setLoading(false)
  }

  const handleCloseDeleteModal = () => {
    setOpenConfirmModal(false)
    setSelectedRowId(null)
  }

  const handleAddNewBill = async (data: FormValues) => {
    const start = (data.startDate ? data.startDate : 0) / 1000
    const end = (data.endDate ? data.endDate : 0) / 1000
    setLoading(true)

    const billRes = await create({
      cost: +data.cost!,
      volume: +data.volume!,
      start,
      end,
      structureId,
      volume_units: data.unit || 'GALLONS_1K',
      rate_method: data.rateMethod,
      effective_rate: data.effectiveRate,
      fees: data.fees,
      eUsage: data.eUsage
    })

    await trackEvent('Bill Created', {
      StartDate: convertTimestampToTimeZone(start, timeZone),
      EndDate: convertTimestampToTimeZone(end, timeZone),
      WaterUsage: +data.volume!,
      VolumeUnit: data.unit || 'GALLONS_1K',
      Cost: +data.cost!,
      RateMethod: data.rateMethod,
      PropertyName: structureName,
      PropertyID: structureId,
      BillID: billRes.id
    })
    console.log(billRes)

    setIsAddBillOpen(false)
    setLoading(false)
    setIsSendBill(true)
    generateBillForSelectedId(billRes.id, {
      StartDate: convertTimestampToTimeZone(start, timeZone),
      EndDate: convertTimestampToTimeZone(end, timeZone),
      WaterUsage: +data.volume!,
      VolumeUnit: data.unit || 'GALLONS_1K',
      Cost: +data.cost!,
      RateMethod: data.rateMethod,
      PropertyName: structureName,
      PropertyID: structureId,
      BillID: billRes.id,
      EffectiveRate: data.effectiveRate,
      lineItems: billRes.line_items
    })
  }

  const handleEditBill = async (data: FormValues) => {
    const start = (data.startDate ? data.startDate : 0) / 1000
    const end = (data.endDate ? data.endDate : 0) / 1000
    setLoading(true)

    await edit({
      id: isEditingBill,
      cost: Number(data.cost),
      volume: Number(data.volume),
      volume_units: data.unit,
      start,
      end,
      structureId,
      rate_method: data.rateMethod,
      effective_rate: data.effectiveRate
    })

    await trackEvent('Bill Edited', {
      StartDate: convertTimestampToTimeZone(start, timeZone),
      EndDate: convertTimestampToTimeZone(end, timeZone),
      WaterUsage: +data.volume!,
      VolumeUnit: data.unit || 'GALLONS_1K',
      Cost: +data.cost!,
      RateMethod: data.rateMethod,
      PropertyName: structureName,
      PropertyID: structureId,
      BillID: isEditingBill,
      EffectiveRate: data.effectiveRate
    })
    setIsSendBill(true)
    generateBillForSelectedId(isEditingBill, {
      StartDate: convertTimestampToTimeZone(start, timeZone),
      EndDate: convertTimestampToTimeZone(end, timeZone),
      WaterUsage: +data.volume!,
      VolumeUnit: data.unit || 'GALLONS_1K',
      Cost: +data.cost!,
      RateMethod: data.rateMethod,
      PropertyName: structureName,
      PropertyID: structureId,
      BillID: isEditingBill,
      EffectiveRate: data.effectiveRate
    })
    setIsEditingBill(null)
    setLoading(false)
  }

  const emptyRows: number = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null)
  const [menuSelectedId, setMenuSelectedId] = React.useState(null)

  const handleMenuClick = (event, id) => {
    setMenuAnchorEl(event.currentTarget)
    setMenuSelectedId(id)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
    setMenuSelectedId(null)
  }

  const addDaysToDate = (unixTimestamp: number, days: number, timeZone?: string | null) => {
    const effectiveTimeZone = timeZone || dayjs.tz.guess()

    const inputDate = new Date(unixTimestamp * 1000)
    inputDate.setDate(inputDate.getDate() + days)

    const year = inputDate.getFullYear()
    const month = String(inputDate.getMonth() + 1).padStart(2, '0')
    const day = String(inputDate.getDate()).padStart(2, '0')

    return dayjs
      .tz(`${year}-${month}-${day}`, effectiveTimeZone)
      .set('hours', 23)
      .set('seconds', 59)
      .set('minutes', 59)
      .set('milliseconds', 0)
      .valueOf()
  }

  return (
    <ErrorBoundary>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar onAdd={() => setIsAddBillOpen(true)} />
          <TableContainer>
            <Table className={classes.table} size="medium">
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                stripeConnect={stripeConnect}
              />
              <TableBody sx={{ position: 'relative' }}>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    let totalCost = 0

                    if (row.lineItems && row.lineItems.length !== 0) {
                      const splitItems = row.lineItems.filter((item) => item.type === 'SPLIT')
                      const flatItems = row.lineItems.filter((item) => item.type === 'FLAT')
                      const unitFlatItems = row.lineItems.filter((item) => item.type === 'UNIT_FLAT')

                      const totalUnits = units.length

                      splitItems.forEach((item) => {
                        totalCost += item.cost
                      })

                      flatItems.forEach((item) => {
                        totalCost += item.cost * totalUnits
                      })

                      unitFlatItems.forEach((item) => {
                        totalCost += item.cost
                      })
                    }

                    if (totalCost === 0) totalCost = row.cost || 0

                    return (
                      <TableRow hover key={row.name}>
                        <TableCell align="center">{`$${formatNumber(totalCost, 2)}`}</TableCell>
                        <TableCell align="center">
                          {dayjs(
                            addDaysToDate(row.billsSentTimestamp ? row.billsSentTimestamp : row.end, 21, timeZone)
                          ).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell align="center">{formatNumber(row.volume, 3)}</TableCell>
                        <TableCell align="center">
                          {convertTimestampToTimeZone(row.start, timeZone, true)} -{' '}
                          {convertTimestampToTimeZone(row.end, timeZone, true)}
                        </TableCell>

                        {stripeConnect && (
                          <TableCell align="center">
                            {row.billsSentTimestamp && (
                              <Stack>
                                <RenderStatus invoices={row.invoices} />
                              </Stack>
                            )}
                          </TableCell>
                        )}
                        <TableCell>
                          <IconButton
                            aria-controls={`menu-${row.id}`}
                            aria-haspopup="true"
                            onClick={(event) => handleMenuClick(event, row.id)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id={`menu-${row.id}`}
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl) && menuSelectedId === row.id}
                            onClose={handleMenuClose}
                          >
                            <MenuItem
                              onClick={() => {
                                generateBillForSelectedId(row.id, {
                                  StartDate: convertTimestampToTimeZone(row.start, timeZone),
                                  EndDate: convertTimestampToTimeZone(row.end, timeZone),
                                  WaterUsage: row.volume,
                                  VolumeUnit: row.volumeUnits || 'GALLONS_1K',
                                  Cost: row.cost,
                                  RateMethod: row.rateMethod,
                                  PropertyName: structureName,
                                  PropertyID: structureId,
                                  BillID: isEditingBill,
                                  EffectiveRate: row.effectiveRate,
                                  invoices: row.invoices,
                                  lineItems: row.lineItems,
                                  billsSentTimestamp: row.billsSentTimestamp
                                })
                                handleMenuClose()
                              }}
                            >
                              View billing summary
                            </MenuItem>
                            {stripeConnect && (
                              <MenuItem
                                onClick={() => {
                                  let a = document.createElement('a')
                                  a.target = '_blank'
                                  a.href = 'https://dashboard.stripe.com/invoices/'
                                  a.click()
                                  handleMenuClose()
                                }}
                              >
                                View Invoices
                              </MenuItem>
                            )}
                            {stripeConnect && (
                              <MenuItem
                                onClick={() => {
                                  generateBillForSelectedId(row.id, {
                                    StartDate: convertTimestampToTimeZone(row.start, timeZone),
                                    EndDate: convertTimestampToTimeZone(row.end, timeZone),
                                    WaterUsage: row.volume,
                                    VolumeUnit: row.volumeUnits || 'GALLONS_1K',
                                    Cost: row.cost,
                                    RateMethod: row.rateMethod,
                                    PropertyName: structureName,
                                    PropertyID: structureId,
                                    BillID: isEditingBill,
                                    EffectiveRate: row.effectiveRate,
                                    invoices: row.invoices,
                                    lineItems: row.lineItems,
                                    billsSentTimestamp: row.billsSentTimestamp
                                  })
                                  setIsPaymentReport(true)
                                  handleMenuClose()
                                }}
                              >
                                View Payment Report
                              </MenuItem>
                            )}
                            {!row.billsSentTimestamp && stripeConnect && (
                              <MenuItem
                                onClick={() => {
                                  generateBillForSelectedId(row.id, {
                                    StartDate: convertTimestampToTimeZone(row.start, timeZone),
                                    EndDate: convertTimestampToTimeZone(row.end, timeZone),
                                    WaterUsage: row.volume,
                                    VolumeUnit: row.volumeUnits || 'GALLONS_1K',
                                    Cost: row.cost,
                                    RateMethod: row.rateMethod,
                                    PropertyName: structureName,
                                    PropertyID: structureId,
                                    BillID: isEditingBill,
                                    EffectiveRate: row.effectiveRate,
                                    lineItems: row.lineItems,
                                    billsSentTimestamp: row.billsSentTimestamp
                                  })
                                  setIsReportTenant(true)
                                  handleMenuClose()
                                }}
                              >
                                Send Bills
                              </MenuItem>
                            )}
                            <MenuItem
                              onClick={() => {
                                generateBillForSelectedId(row.id, {
                                  StartDate: convertTimestampToTimeZone(row.start, timeZone),
                                  EndDate: convertTimestampToTimeZone(row.end, timeZone),
                                  WaterUsage: row.volume,
                                  VolumeUnit: row.volumeUnits || 'GALLONS_1K',
                                  Cost: row.cost,
                                  RateMethod: row.rateMethod,
                                  PropertyName: structureName,
                                  PropertyID: structureId,
                                  BillID: isEditingBill,
                                  EffectiveRate: row.effectiveRate,
                                  lineItems: row.lineItems,
                                  billsSentTimestamp: row.billsSentTimestamp
                                })
                                setIsReportTenant(true)
                                handleMenuClose()
                              }}
                            >
                              Send tenant reports
                            </MenuItem>
                            {!row.billsSentTimestamp && (
                              <MenuItem
                                onClick={() => {
                                  setIsEditingBill(row.id)
                                  handleMenuClose()
                                }}
                              >
                                Edit Bill
                              </MenuItem>
                            )}
                            {!row.billsSentTimestamp && (
                              <MenuItem
                                onClick={() => {
                                  handleClickDelete(row.id, row)
                                  handleMenuClose()
                                }}
                              >
                                Delete Bill
                              </MenuItem>
                            )}
                            <MenuItem
                              onClick={() => {
                                generateBillForSelectedId(
                                  row.id,
                                  {
                                    StartDate: convertTimestampToTimeZone(row.start, timeZone),
                                    EndDate: convertTimestampToTimeZone(row.end, timeZone),
                                    WaterUsage: row.volume,
                                    VolumeUnit: row.volumeUnits || 'GALLONS_1K',
                                    Cost: row.cost,
                                    RateMethod: row.rateMethod,
                                    PropertyName: structureName,
                                    PropertyID: structureId,
                                    BillID: isEditingBill,
                                    EffectiveRate: row.effectiveRate,
                                    invoices: row.invoices,
                                    lineItems: row.lineItems,
                                    billsSentTimestamp: row.billsSentTimestamp
                                  },
                                  'pdf'
                                )
                                handleMenuClose()
                              }}
                            >
                              Export PDF
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                generateBillForSelectedId(
                                  row.id,
                                  {
                                    StartDate: convertTimestampToTimeZone(row.start, timeZone),
                                    EndDate: convertTimestampToTimeZone(row.end, timeZone),
                                    WaterUsage: row.volume,
                                    VolumeUnit: row.volumeUnits || 'GALLONS_1K',
                                    Cost: row.cost,
                                    RateMethod: row.rateMethod,
                                    PropertyName: structureName,
                                    PropertyID: structureId,
                                    BillID: isEditingBill,
                                    EffectiveRate: row.effectiveRate,
                                    invoices: row.invoices,
                                    lineItems: row.lineItems,
                                    billsSentTimestamp: row.billsSentTimestamp
                                  },
                                  'csv'
                                )
                                handleMenuClose()
                              }}
                            >
                              Export CSV
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component={'div'}
            onPageChange={handleChangePage}
          />
        </Paper>
      </div>

      <BillForm
        type="ADD"
        units={units}
        volumeUnits={volumeUnits}
        open={isAddBillOpen}
        onClose={() => setIsAddBillOpen(false)}
        onSubmit={handleAddNewBill}
        rateMethod={defaultRateMethod}
        loading={loading}
        propertyTimeZone={timeZone}
        stripeConnect={stripeConnect}
      />

      {/* Edit Bill */}
      <BillForm
        type={'EDIT'}
        units={units}
        volumeUnits={volumeUnits}
        open={Boolean(isEditingBill)}
        onClose={() => setIsEditingBill(null)}
        cost={rows.find((r) => r.id === isEditingBill)?.cost}
        volume={rows.find((r) => r.id === isEditingBill)?.volume}
        start={(rows.find((r) => r.id === isEditingBill)?.start || 0) * 1000}
        end={(rows.find((r) => r.id === isEditingBill)?.end || 0) * 1000}
        rateMethod={rows.find((r) => r.id === isEditingBill)?.rateMethod || defaultRateMethod}
        onSubmit={handleEditBill}
        loading={loading}
        propertyTimeZone={timeZone}
        stripeConnect={stripeConnect}
      />

      {selectedBillingDetail && (
        <BillBreakdown
          open={!!selectedBillingDetail}
          onClose={() => {
            setSelectedBillingDetail(null)
            setBillingDetailAction(null)
            setIsReportTenant(false)
            setIsSendBill(false)
            setIsPaymentReport(false)
          }}
          structureName={structureName}
          bill={selectedBillingDetail}
          autoDownload={billingDetailAction}
          needReportButton={isReportTenant}
          needSendBillButton={isSendBill}
          structureAddress={structureAddress}
          timeZone={timeZone}
          stripeConnect={stripeConnect}
          invoices={invoicesData}
          isPaymentReport={isPaymentReport}
          lineItems={lineItems}
          billsSentTimestamp={billsSentTimestamp}
        />
      )}

      <DeleteConfirmDialog
        open={openConfirmModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handleDeleteConfirm}
        title="Do you want to remove this bill?"
        loading={loading}
      />
    </ErrorBoundary>
  )
}

const RenderStatus = ({ invoices }: { invoices: IInvoices[] }) => {
  const paidInvoices = invoices.filter((invoice) => invoice.stripe_last_status === 'paid')

  const percentage = invoices.length > 0 ? (paidInvoices.length / invoices.length) * 100 : 0

  return <LinearProgressWithLabel value={percentage} label={`${paidInvoices.length} of ${invoices.length}`} />
}
