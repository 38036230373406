import { Box, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'

export const BillHistoryTable = (): JSX.Element => {
  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ position: 'relative' }}>
                Amount
              </TableCell>
              <TableCell align="center" sx={{ position: 'relative' }}>
                Due
              </TableCell>
              <TableCell align="center" sx={{ position: 'relative' }}>
                Email
              </TableCell>
              <TableCell align="center" sx={{ position: 'relative' }}>
                Billing Period
              </TableCell>
              <TableCell align="center" sx={{ position: 'relative' }}>
                Status
              </TableCell>
              <TableCell align="center" sx={{ position: 'relative' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">$300</TableCell>
              <TableCell align="center">December 1st, 2024</TableCell>
              <TableCell align="center">some@email.com</TableCell>
              <TableCell align="center">09/01/24 - 09/30/24</TableCell>
              <TableCell align="center">
                <Chip label={'Paid'} sx={{ backgroundColor: '#E8F3EE', borderRadius: '8px', color: '#198754' }} />
              </TableCell>
              <TableCell align="center">
                <Box sx={{ cursor: 'pointer' }}>
                  <DownloadIcon />
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">$300</TableCell>
              <TableCell align="center">December 1st, 2024</TableCell>
              <TableCell align="center">some@email.com</TableCell>
              <TableCell align="center">09/01/24 - 09/30/24</TableCell>
              <TableCell align="center">
                <Chip
                  label={'Uncollectible'}
                  sx={{ backgroundColor: '#FCEBEC', borderRadius: '8px', color: '#DC3545' }}
                />
              </TableCell>
              <TableCell align="center">
                <Box sx={{ cursor: 'pointer' }}>
                  <DownloadIcon />
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">$300</TableCell>
              <TableCell align="center">December 1st, 2024</TableCell>
              <TableCell align="center">some@email.com</TableCell>
              <TableCell align="center">09/01/24 - 09/30/24</TableCell>
              <TableCell align="center">
                <Chip label={'Open'} sx={{ backgroundColor: '#EDF4FA', borderRadius: '8px', color: '#4890CC' }} />
              </TableCell>
              <TableCell align="center">
                <Box sx={{ cursor: 'pointer' }}>
                  <DownloadIcon />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
